import axios from "axios";
import store from "../store/index";
import router from "@/router";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axios.defaults.withCredentials = true;
export let cancelSource = axios.CancelToken.source();

export const requestInterceptor = config => {
    config.cancelToken = cancelSource.token;
    return config;
};

export const HTTP = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    headers: {
        Authorization: `JWT ${store.getters.jwt}`,
        "Content-Type": "application/json",
    },
    xhrFields: {
        withCredentials: true,
    },
});

HTTP.interceptors.request.use(requestInterceptor);

let countRequestPending = 0;

const checkRequestCount = () => {
    if (!countRequestPending) {
        store.dispatch("loader", false);
    }
};
HTTP.interceptors.request.use(
    function (config) {
        countRequestPending++;
        store.dispatch("loader", true);
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
HTTP.interceptors.response.use(
    function (response) {
        countRequestPending--;
        checkRequestCount();
        return response;
    },
    function (error) {
        countRequestPending--;
        checkRequestCount();
        if (error.response.status === 404) {
            router.push('/404')
        }
        if (error.response.status === 403) {
            alert("Действие запрещено")
        }
        if (error.response.status === 401) store.dispatch("logout");

        return Promise.reject(error);
    }
);

export default HTTP;
