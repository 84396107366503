<template>
  <main class="dashboard">
    <BackButton></BackButton>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{{ object?.text }}</h5>
        <small>{{ object?.date }}</small>
        <div class="content-area" v-if="object?.content">
          <div class="content-wrapper">
            <div class="content-body">
              <template v-for="element in parsed_content">
                <div :class="'element' + (element.edit?' focus':'') + (element.comment?' hover':'')">
                  <span class="text" v-html="element.text"></span>
                  <div class="comment-small" v-if="element.comment && !element.comment?.open"
                       @click="element.comment.open = true"><i class="bi bi-chat-left-dots"></i></div>

                  <div class="comment" v-if="element.comment && element.comment?.open">
                    <div v-if="element.comment.date_update" class="date">
                      {{ $moment(element.comment.date_update).format("DD.MM.YYYY HH:MM") }}
                    </div>
                    <a v-if="element.comment.id" @click.prevent="element.comment.open=false" class="close">
                      <i class="bi bi-x-lg"></i>
                    </a>
                    <p>{{ element.comment.text }} </p>
                  </div>
                </div>
              </template>
            </div>
              <div class="chat">
<Chat :object_id="object.id" :content_type="object.content_type"></Chat>
          </div>
          </div>

        </div>

      </div>


    </div>


  </main>
</template>

<script>
import {DictionaryApi} from '@/api/dictionary'
import Chat from "@/components/chat/Chat.vue";

export default {
  name: 'ContentDetail',
  data() {
    return {
      name: 'contents',
      object: null,
      parsed_content: []
    }
  },
  components: {Chat},
  mounted: function () {
    DictionaryApi.get_detail(this.name, this.$route.params.text_id).then(response => {
      this.object = response
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(this.object.content.text, 'text/html');
      htmlDoc.querySelector("body").children.forEach((x, i) => {
        this.parsed_content.push({text: x.outerHTML, comment: this.object.content.comments.find(x => x.index === i)})
      })

    })

  },
  computed: {},
  methods: {},

}

</script>
<style lang="scss">
.content-area {
  display: flex;
  gap: 20px;

  .content-wrapper {
    display: flex;
    gap: 50px;
  }
  .chat {
    flex: 1 1 auto;
    width: 30%;
  }

  .content-body {
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    display: block;
    position: relative;

    .element {
      display: flex;
      justify-content: space-between;
      position: relative;
      cursor: pointer;

      .date {
        font-size: 10px;
        color: #333;
        position: absolute;
        left: 0;
        top: 0;
        height: auto;
        margin: 0;
      }

      .close {
        font-weight: bold;
        color: #000;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 12px;
      }

      &.focus {
        z-index: 3;
      }

      .text {
        margin-right: 15px;
      }

      &:hover, &.hover {
        background: rgba(213, 154, 0, 0.2);

        .add-comment {
          display: block;
        }
      }

      .comment {
        position: absolute;
        right: -220px;
        border: 3px solid #666;
        border-radius: 10px;
        width: 200px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        background: #fff;
        z-index: 2;

      }

    }

  }

  img {
    display: block;
    width: 100% !important;
  }
}
</style>