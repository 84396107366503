<template>
  <header class="navbar d-flex p-4">

    <router-link class="navbar-brand col-auto" to="/">
      <img src="@/assets/images/logo.svg" alt="Logo"/>
    </router-link>


    <div class="d-flex align-items-center justify-content-between col" v-if="$store.getters.isLoggedIn">
      <form class="col-4 me-auto" role="search">
        <template v-if="!$route.meta.base_name">
          <i class="bi bi-search"></i>
          <input type="search" class="form-control black" placeholder="Поиск..." v-model="query"
                 @click="query_open=!query_open;" aria-label="Поиск">
        </template>
        <ul :class="`dropdown-menu search-result${query_open?' open':''}`">
          <li><h6 class="dropdown-header">Результаты поиска</h6></li>
          <template v-if="searches.length && query.length>1">
            <li v-for="search in searches" @click="query_open=!query_open">
              <router-link class="dropdown-item link" :to="{name: 'project_detail', params:{id: search.id}}">
                <span
                    v-html="search.name?.replace(new RegExp(query, 'ig'), `<span class='text-bg-warning'>${query}</span>`)"></span>
                <span
                    :class="`badge ${search.project_type}`">{{ search.get_project_type_display }}</span>
              </router-link>

            </li>
          </template>
          <li v-else><p class="dropdown-item">Ничего не найдено</p></li>
        </ul>
      </form>

      <div class="col-auto d-flex align-items-center">
        <template v-if="!$route.meta.base_name">
          <Alerts></Alerts>
        </template>
        <div class="dropdown">
          <template v-if="textHost">
            <div type="button" class="user btn text-white">
              <object class="avatar" :data="$store.getters.user.picture" type="image/jpeg"></object>
              <span class="name">
                <span class="full">{{ $store.getters.user.fullname }}</span>
                <span class="email">{{ $store.getters.user.email }}</span>
              </span>
              <a href="" @click.prevent="logout"><i class="bi bi-box-arrow-right"></i>
              </a>
            </div>
          </template>
          <template v-else>
            <button type="button" class="user btn text-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
              <object class="avatar" :data="$store.getters.user.picture" type="image/jpeg"></object>
              <span class="name">
                <span class="full">{{ $store.getters.user.fullname }}</span>
                <span class="email">{{ $store.getters.user.email }}</span>
              </span>

              <i class="bi bi-gear-fill"></i>
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
              <template v-if="!$store.getters.user.contractor">
                <li>
                  <router-link to="/settings/" class="dropdown-item" type="button">Настройки</router-link>
                </li>
                <li>
                  <router-link to="/info/" class="dropdown-item" type="button">Состояние сервера</router-link>
                </li>
                <li>
                  <router-link to="/services/" class="dropdown-item" type="button">Состояние парсеров</router-link>
                </li>
                <li>
                  <router-link to="/authy/" class="dropdown-item" type="button">Authy</router-link>
                </li>
                <li>
                  <router-link to="/services_tr/" class="dropdown-item" type="button">Состояние парсеров TR
                  </router-link>
                </li>
              </template>
              <li>
                <hr class="dropdown-divider">
              </li>

              <li>
                <button class="dropdown-item" type="button" @click="logout"><i class="bi bi-box-arrow-left"></i> Выход
                </button>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </div>

  </header>
</template>

<script>

import {HTTP} from '@/api/common'
import axios from 'axios'
import Alerts from "@/components/Alerts.vue";

const request = axios.CancelToken.source();

export default {
  name: 'Header',
  data() {
    return {
      open_menu: false,
      open_user: false,
      project_name: '',
      est_name: '',
      curr_project: false,
      show_alert: true,
      query_open: false,
      query: '',
      controller: new AbortController(),
      request: false,
      searches: []
    }
  },
  components: {Alerts},
  watch: {

    query: function (val) {
      if (val.length > 1) {
        this.searchProject()
      } else {
        this.searches = []
      }

    },
  },
  computed: {
    textHost() {
      return location.host === process.env.VUE_APP_TEXT_API;
    }
  },
  methods: {
    cancelSearch() {
      this.request.cancel();
      this.request = null;
    },
    searchProject: function () {
      let $this = this;
      if (this.request) this.cancelSearch();
      const axiosSource = axios.CancelToken.source();
      this.request = {cancel: axiosSource.cancel};
      HTTP.get(`projects/search_by_q/?name=${this.query}`, {cancelToken: axiosSource.token}).then(resp => {
        $this.request = null;
        $this.searches = resp.data
        $this.query_open = true
      })
    },
    logout: function () {
      let path_after_logout = this.$store.getters.user.contractor ? '/contractor_login' : '/login'
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push(path_after_logout)
          })
    },
  }
}
</script>
<style>
form[role="search"] {
  position: relative;
}

.search-result.open {
  display: block;
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
}
</style>
