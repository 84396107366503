<template>
  <section>
    <div class="d-flex d-flex justify-content-between align-items-end">
      <div class="col-6">
        <div class="dropdown" v-if="active_country">
          <button class="btn btn-filter "
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
            {{ active_country.name }}
          </button>
          <ul class="dropdown-menu ">
            <template v-for="county in project.countries">
              <li @click="active_country=county;">{{ county.name }}</li>
            </template>
          </ul>
        </div>
      </div>
      <div class="col-6 position-relative align-content-end">
        <date-picker
            v-model="active_date"
            locale="ru"
            :clearable="false"
            format="dd.MM.yyyy"
            model-type="dd.MM.yyyy"
            auto-apply
            :allowed-dates="allowedDates"
            :enable-time-picker="false"
        ></date-picker>
      </div>
    </div>
    <SmallProject v-if="project" v-bind:project="project"/>
    <template v-if="project && project.stats">
      <template v-for="(states, index) in all_states">
        <div class="d-flex stats-wrap" v-if="!hide_more || !index">
          <div class="stats d-flex flex-nowrap">
            <div v-for="tone in tones" :class="`info-block ${tone}`">
              <div v-for="state in states">
                <div class="title">Всего в Топ-{{ state }}</div>
                <div class="line row" v-for="type in types">
                  <div class="col-4">{{ type.name }}:</div>
                  <div class="col-2 value">
                    <span class="text-danger">{{ project.stats[tone][`_${state}${type.param}`] }}</span>
                  </div>
                  <div class="col value row flex-nowrap">
                    <i class="ico yandex"></i>
                    <span>{{ project.stats[tone][`_${state}${type.param}_y`] }}</span>
                  </div>
                  <div class="col value row flex-nowrap">
                    <i class="ico google"></i>
                    <span>{{ project.stats[tone][`_${state}${type.param}_g`] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <button :class="`btn btn-${hide_more?'outline-':''}dark`" @click="hide_more=!hide_more">
      <template v-if="!hide_more">Скрыть</template>
      <template v-else>Показать</template>
      больше выборок <i :class="`bi bi-chevron-${hide_more?'down':'up'}`"></i></button>

    <ul class="list-group" v-if="project?.id && project.stats">
      <router-link :to="{ name: 'links_info', params: { id: project.id }, query: { state:  20, country_code: active_country?.code,
                                  filter_type: 'auto_new', date: active_date, uniq: true}}"
                   class="list-group-item d-flex justify-content-between align-items-center">
        <span>Новая автоматическая <br>нецелевая информация Топ-20</span>
        <span class="badge bg-archive">{{ String(project.stats.new_negative).padStart(3, '0') }}</span>
      </router-link>
      <router-link :to="{ name: 'links_info', params: { id: project.id }, query: { state:  20, country_code: active_country?.code,
                                filter_type: 'auto_all',  engine: '', date: active_date, uniq: true}}"
                   class="list-group-item d-flex justify-content-between align-items-center">
        <span>Автоматически размечено в Топ-20</span>
        <span class="badge bg-gray">{{ String(project.stats.auto_mark).padStart(3, '0') }}</span>
      </router-link>
      <router-link :to="{ name: 'links_info', params: { id: project.id }, query: { state:  20, country_code: active_country?.code,
                                 filter_type: 'no_tone', engine: '', date: active_date, uniq: true}}"
                   class="list-group-item d-flex justify-content-between align-items-center">
        <span>Неразмеченные ссылки в Топ-20</span>
        <span class="badge bg-archive">{{ String(project.stats.no_mark).padStart(3, '0') }}</span>
      </router-link>
      <router-link :to="{ name: 'links_info', params: { id: project.id }, query: { country_code: active_country?.code,
                                      filter_type: 'no_content',  engine: '', date: active_date, uniq: true, state: 100}}"
                   class="list-group-item d-flex justify-content-between align-items-center">
        <span>Ссылки не получившие контент</span>
        <span class="badge bg-archive">{{ String(project.stats.no_content).padStart(3, '0') }}</span>
      </router-link>
    </ul>
    <router-link v-if="project?.id" :to="{name: 'project_result', params: {id: project.id}}"
                 class="btn btn-outline-dark mb-2">
      Топ ссылок
    </router-link>
  </section>
</template>
<script>
import SmallProject from "@/mobile/includes/SmallProject.vue";
import {ProjectApi} from "@/api/project";
import {MobileApi} from "@/api/mobile";
import moment from "moment/moment";

export default {
  components: {SmallProject},
  data() {
    return {
      tones: ['negative', 'positive'],
      all_states: [[10, 20], [50, 100]],
      hide_more: true,

      project: null,
      active_country: null,
      old_active_country: null,
      active_date: null,
      old_active_date: null,
      stats: null,
      types: [{name: 'Общий', param: ''}, {name: 'Уникальный', param: '_uniq'}],
      allowedDates: []
    }
  },
  computed: {},
  watch: {
    $route() {
      this.project = null
      this.active_date = null
      this.getAvailDate()
      this.getProject()
    },
    active_country: function (val) {
      if (this.old_active_country !== null && this.old_active_country?.code !== val?.code) this.getProject();
      this.old_active_country = val;

    },
    active_date: function (val) {
      if (this.old_active_date !== null) this.getProject();
      this.old_active_date = val;
    },
  },
  mounted() {
    this.getProject()
    this.getAvailDate()
  },
  methods: {
    getAvailDate() {
      ProjectApi.get_avail_date_calendar(this.$route.params.id).then(response => this.allowedDates = response)
    },
    getProject: function () {
      let $this = this;
      MobileApi.list(this.$route.params.id).then(response => {
        $this.project = response.results[0];
        if (!$this.active_date) $this.active_date = moment($this.project.last_parser, "DD/MM/YYYY").format("DD.MM.YYYY");
        if (!$this.active_country) {
          $this.active_country = this.project ? (this.project.countries || []).find(x => x.active) : null;
          if (!$this.active_country) $this.active_country = (this.project?.countries || [])[0];
        }
        MobileApi.get($this.project.id, $this.active_country.code, $this.active_date).then(response => {
              $this.project = {...$this.project, ...response}
            }
        )
      })
    },
  }

}
</script>