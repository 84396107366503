import router from '@/router/index'
import HTTP from '@/api/common'
import axios from 'axios'
import {useCookies} from "vue3-cookies";
import {UserApi} from "@/api/user";
import {
    SET_LOGOUT,
    SET_ROUTE,
    SUCCESS_LOGIN,
    UPDATE_TOKEN,
    REMEMBER_ME,
    HIDE_SIDEBAR,
    TOGGLE_SIDEBAR
} from '@/store/mutation-types'

const {cookies} = useCookies();

const User = {
    state: {
        user: {},
        route: null,
        remember: localStorage.getItem("remember") || "",
        jwt: cookies.get("token") || null,
        sidebar_state: !localStorage.getItem("hide_sidebar"),
        settings: {
            base: {
                sidebar: true,
                per_page: 10,
                views: "big",
                links: {
                    per_page: 50,
                }
            },
            projects: {
                1: {
                    sort: 1,
                    state: 10,
                    country_code: "RU",
                    links: {}
                }
            }
        }
    },
    mutations: {
        [SET_ROUTE](state, route) {
            state.route = route
        },
        [SUCCESS_LOGIN](state, user) {
            state.user = user
        },
        [UPDATE_TOKEN](state, token) {
            cookies.set("token", token, '30D', "/");
            state.jwt = token;
        },
        [SET_LOGOUT](state) {
            state.user = {};
            cookies.remove("token", "/");
            state.jwt = null;
        },
        [REMEMBER_ME](state, username) {
            if (username) localStorage.setItem("remember", username);
            else localStorage.removeItem("remember");
            state.remember = username;
        },
        [TOGGLE_SIDEBAR](state) {
            state.sidebar_state = !state.sidebar_state;
            if (state.sidebar_state) localStorage.removeItem("hide_sidebar");
            else localStorage.setItem("hide_sidebar", "true");
        },
    },
    actions: {
        setSettings({commit}, settings){
            UserApi.set_settings(settings).then(response => commit(SUCCESS_LOGIN, response))

        },
        init({dispatch}, need_permission) {
            if (this.getters.jwt) {
                return Promise.all([
                    dispatch('refresh_token'),
                    dispatch('get_user')
                ])
            }
        },
        toggle_sidebar({commit}) {
            commit(TOGGLE_SIDEBAR)
        },
        login({commit, dispatch}, user) {
            return new Promise((resolve, reject) => {
                const username = user.username, password = user.password;
                commit(REMEMBER_ME, user.remember ? username : "")
                HTTP.post('/auth/staff/obtain_token/', {
                    username,
                    password
                }).then((response) => {
                    commit(UPDATE_TOKEN, response.data.token)
                    dispatch('get_user');
                    resolve(response);
                }).catch((error) => {
                    if (error.response.status === 423) dispatch("do_change_password", error.response.data)
                    reject(error)
                })
            })
        },

        check_tokens({commit, dispatch}, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/auth/staff/check_token/', data).then((response) => {
                    resolve(response);
                })
                    .catch((error) => {
                        reject(error)
                    });
            })
        },

        refresh_token({commit, dispatch}) {
            let $this = this;
            if ($this.getters.jwt) return new Promise((resolve, reject) => {
                HTTP.post('/auth/refresh_jwt_token/', {token: $this.getters.jwt}).then((response) => {
                    commit(UPDATE_TOKEN, response.data.token);
                    resolve(response);
                }).catch((error) => {
                    if (error.response.status === 423) dispatch("do_change_password", error.response.data)
                    if (error.response.status === 401) dispatch("logout")
                    reject(error)
                })
            })
        },

        do_change_password({commit, dispatch}, data) {
            HTTP.get('/auth/logout/')
                .then(() => {
                    commit(SET_LOGOUT);
                    router.push(`/forced_password_change/?token=${data.token}&uuid=${data.uuid}`);
                })
        },

        get_user({commit, dispatch}) {
            let $this = this;
            return new Promise((resolve) => {
                const base = {
                    baseURL: process.env.ROOT_API,
                    headers: {
                        Authorization: `JWT ${$this.getters.jwt}`,
                        "Content-Type": "application/json",
                    },
                    xhrFields: {
                        withCredentials: true,
                    },
                };
                const axiosInstance = axios.create(base);
                axiosInstance({
                    url: "/staff/get_info/",
                    method: "get",
                    params: {},
                }).then((response) => {
                    commit(SUCCESS_LOGIN, response.data);
                    resolve(response);
                }).catch((error) => {
                    if (error.response.status === 401) dispatch('logout')
                });
            })
        },

        logout({commit}) {
            let $this = this;
            return new Promise((resolve) => {
                axios.get('/auth/logout/')
                    .then(resp => {
                        commit(SET_LOGOUT);
                        router.push({name: 'login', query: {'next': $this.getters.getRoute.path}});
                        resolve()
                    })

            })
        }
    },
    getters: {
        isLoggedIn: (state) => !!state.user.is_active,
        user: (state) => state.user,
        jwt: (state) => state.jwt,
        getRoute: (state) => state.route,
        remember: (state) => state.remember,
        sidebar_state: (state) => state.sidebar_state
    },

};

export default User;