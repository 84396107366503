<template>
  <div class="phrases col-5 mt-2">
    <div><a href="" @click.prevent="$func.showElement('schemas_link')"
            style="border-bottom: 1px dotted #ccc">Группы ссылок: <b>{{ groups.length }}</b></a>
      <button class=" btn btn-sm text-success"
              @click="addGroup()"
              type="button" title="Добавить фразу"><i class="bi bi-plus-square"></i></button>
    </div>


    <div class="list hide" id="schemas_link">
      <template v-for="(group, index) in groups">
        <div class="group">
          <div class="color-group" :style="{background: `rgb(${group.color_rgb})`}"></div>
          <input v-if="group.edit" v-model="group.name" class="form-control">
          <span v-else>{{ group.name }}</span>
          <div class="buttons">
            <button v-if="group.edit" class="btn btn-sm btn-success text-white" type="button"
                    @click="saveGroup(group, index)"><i
                class="bi bi-check2"></i></button>
            <button v-if="group.edit" class="btn btn-sm btn-danger text-white" type="button"
                    @click="cancelEdit(group, index)"><i
                class="bi bi-x-lg"></i></button>
            <button v-if="group.id && !group.edit" class="btn btn-sm btn-success text-white" type="button"
                    @click="group.edit = true;"><i
                class="bi bi-pencil"></i></button>
            <button v-if="group.id && !group.edit" class="btn btn-sm btn-danger text-white" type="button"
                    @click="removeGroup(group, index)"><i
                class="bi bi-trash"></i></button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {GroupApi} from "@/api/groups";

export default {
  name: "LinkColors",
  props: {
    project_id: Number,
    country_code: String,
    setGroup: Function
  },
  components: {},
  data() {
    return {
      groups: [],
      defaultColors: [],
    }
  },
  watch: {
    country_code() {
      this.getSchemes()
    },
    groups(){
      this.setGroup(this.groups)
    }
  },
  computed: {},
  mounted() {
    this.getSchemes()
  },
  methods: {
    cancelEdit(group, index) {
      group.edit = false;
      if (!group.id) this.groups.splice(index, 1)
    },
    getNextColor() {
      const exist_color = this.groups.map(c => JSON.stringify(c.color_rgb))
      const next = this.defaultColors.map(x => x).filter(value => !exist_color.includes(JSON.stringify(value)))
      if (next.length) return next[0]
      return null
    },
    addGroup() {
      const color = this.getNextColor();
      if (color) {

        this.groups.push({id: null, name: '', color_rgb: this.getNextColor(), edit: true});
        this.$func.showElement('schemas_link', true);
      } else {
        this.$notify({
          type: 'error',
          text: "Добавлено максимальное количество групп"
        })
      }
    },
    saveGroup(group, index) {
      group.country_code = this.country_code;
      GroupApi.add_group(this.project_id, group).then(response => this.groups.splice(index, 1, response))

    },
    removeGroup(group, index) {
      GroupApi.remove_group(this.project_id, group).then(r => this.groups.splice(index, 1))
    },
    getSchemes() {
      GroupApi.list(this.project_id, this.country_code).then(r => {
        this.groups = r.groups;
        this.defaultColors = r.default
      });
    }

  }
}
</script>

<style scoped>
.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  &.hide{
    display: none;
  }

  .group {
    display: flex;
    gap: 5px;
    height: 30px;

    input {
      height: 30px;
    }

    .buttons {
      margin-left: auto;
      display: flex;
      gap: 3px;
    }

    .color-group {
      height: 30px;
      width: 30px;
      flex: 0 0 auto;
    }
  }
}

</style>
