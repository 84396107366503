<template>
  <section>
        <draggable v-if="edit" v-model="projects" item-key="id" class="projects">
    <template #item="{element}">
      <SmallProject v-bind:project="element"/>
    </template>
        </draggable>
    <template v-else v-for="project in projects">
      <SmallProject v-bind:project="project"/>
    </template>
    <button class="edit-btn" @click="edit=!edit;">
      <i v-if="edit" class="bi bi-check2"></i>
      <i v-else class="bi bi-pencil-fill"></i>
    </button>
  </section>
</template>
<script>

import SmallProject from "@/mobile/includes/SmallProject.vue";
import draggable from 'vuedraggable'

export default {
  name: "Front",
  components: {SmallProject, draggable},

  data() {
    return {
      edit: false,
    }
  },
  computed: {
    projects: {
      get: function () {
        return this.$store.getters.favorites.map((obj, index) => ({...obj, index: index}));
      },
      set: function (value) {

        let data = {};
        value.forEach((project, index) => {
          if (index !== project.index) {
            data[project.id] = {sort: index}
            project.index = index
          }
        })
        this.$store.dispatch("setSettings", {projects: data})
        this.$store.commit('setFavorites', value)
      }
    },
  },
  watch: {},
  mounted: function () {
  },
  methods: {},
}
</script>
<style>
.edit-btn {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  background: transparent;
  border-width: 1px;
  position: fixed;
  right: 10px;
  bottom: 10px;
}
#mobile_app [data-draggable="true"]{
  border: 1px #000 solid!important;
}
</style>