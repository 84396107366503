<template>
  <main class="dashboard links">
    <div class="mb-4">
      <h2>Оценка проекта: {{ project?.name }}</h2>
      <BackButton></BackButton>
    </div>
    <div class="filters mb-5 d-flex">
      <MultiSelect v-if="active_country?.code"
                   v-model="active_country"
                   :options="project.countries"
                   :multi="false"
                   :required="true"
                   name="Страна"></MultiSelect>
      <div class="date" style="margin-left: auto">
        {{$moment(project?.last_parser).format("DD.MM.YYYY HH:mm")}}
      </div>

    </div>
    <div class="table-responsive" style="height: 100%">
      <table class="table">
        <thead>
        <tr class="sticky-top">
          <th colspan="6"></th>
          <th v-for="keyword in keywords" colspan="2">{{keyword.name}}</th>
        </tr>
        <tr class="sticky-top">
          <th>№</th>
          <th>URL</th>
          <th>Площадка</th>
          <th>Дата публикации</th>
          <th>Дата индексации в Google</th>
          <th>Дата индексации в Yandex</th>
          <template v-for="keyword in keywords">
            <th v-for="engine in $ENGINES">{{engine}}</th>
            </template>
        </tr>

        </thead>
        <tbody>
        <template v-for="(link, index) in table">
          <tr>
            <td>{{index+1}}</td>
            <td><a target="_blank" :href="link.link">{{ link.link }}</a></td>
            <td>{{ getPlatformName(link) }}</td>
            <td><span v-if="link.date_publication">{{$moment(link.date_publication).format("DD.MM.YYYY") }}</span></td>
            <td v-for="engine in $ENGINES">
              {{ link.searches[engine+"_index"]?$moment(link.searches[engine+"_index"]).format("DD.MM.YYYY"):"-" }}
            </td>
            <template v-for="keyword in keywords">
            <td v-for="engine in $ENGINES">{{link.searches.results.find(x=>x.keyword_id==keyword.id&&x.engine==engine)?.place || "-"}}</td>
            </template>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </main>
</template>

<script>
import moment from 'moment';
import CountryChoice from "@/components/CountryChoice.vue";
import HTTP from "@/api/common";

export default {
  name: 'ProjectStat',
  components: {
    CountryChoice
  },

  data() {
    return {
      table: [],
      project: null,
      active_country: null
    }
  },
  computed: {
    keywords(){
      return this.project?.keywords.filter(x=>!x.countries.length || x.countries.find(c => c.code===this.active_country.code))
    }
  },
  watch: {
    active_country(){
      this.getData();
    }
  },
  mounted: function () {
    let $this = this;
    HTTP.get(`planfix/target/get_project/?project_id=${this.$route.params.id}`).then((response) => {
      $this.project = response.data;
      console.log(this.project)
      $this.active_country = $this.project.countries[0];

    });

  },
  methods: {
    getPlatformName(link){
      if (link.pf_platform_name) return link.pf_platform_name;
      return new URL(link.link).host
    },
    getData() {
      let $this = this;
      HTTP.get(`planfix/target/get_pf_links/?country_code=${this.active_country.code}&project_id=${this.$route.params.id}`).then((response) => {
        $this.table = response.data
      });
    }

  }
}

</script>

<style scoped lang="scss">
td, th{
  border-right: 1px solid #cecece;
   border-bottom: 1px dotted #cecece;
}

</style>
