import { HTTP } from "./common";

export const GroupApi = {
  list(id, country_code) {
    return HTTP.get(
      `groups/${id}/get_groups/?country_code=${country_code}`
    ).then((response) => {
      return response.data;
    });
  },
  add_group(id, data) {
    return HTTP.post(
      `groups/${id}/add_group/`, data
    ).then((response) => {
      return response.data;
    });
  },
  remove_group(id, data) {
    return HTTP.post(
      `groups/${id}/remove_group/`, data
    ).then((response) => {
      return response.data;
    });
  },
  add_link(id, data) {
    return HTTP.post(
      `groups/${id}/add_link/`, data
    ).then((response) => {
      return response.data;
    });
  },


};
