import {HTTP} from './common'

export const DictionaryApi = {
  get_detail(name, id) {
    return HTTP.get(`dictionary/${name}/${id}/get_detail`).then(response => {
      return response.data
    });
  },
};

