import { HTTP } from "./common";

export const ProjectApi = {

  list(filter) {
    return HTTP.get(`projects/?${filter ? filter : ""}`).then((response) => {
      return response.data;
    });
  },
  update(object) {
    if (object.id)
      return HTTP.put(`projects/${object.id}/`, object).then((response) => {
        return response.data;
      });
    else
      return HTTP.post("projects/", object,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }).then((response) => {
        return response.data;
      });
  },
  countries() {
    return HTTP.get(`countries/`).then((response) => {
      return response.data;
    });
  },

  update_tr_settings(id, data) {
    return HTTP.post(`projects/${id}/update_tr_settings/`, data).then(response => {
      return response.data
    })
  },

  save_current_project_to_snapshot(id) {
    return HTTP.post(`projects/${id}/save_current_project_to_snapshot/`).then(response => {
      return response
    })
  },

  get_avail_date_calendar(project_id) {
    return HTTP.get(`projects/${project_id}/get_avail_date_calendar`).then((response) => {
      return response.data;
    });
  },
  screen_list(project_id, date) {
    return HTTP.get(`projects/${project_id}/screen_list/${date ? "?date="+date : ""}`).then((response) => {
      return response.data;
    });
  },
  screen_to_png(id, query) {
    return HTTP.get(`projects/${id}/screen_to_png/?${query}`, {responseType: 'blob'}).then((response) => {
      return response.data;
    });
  },
  set_active_country(id, country_code) {
    return HTTP.get(`projects/${id}/set_active_country/?country_code=${country_code}`).then((response) => {
      return response.data;
    });
  },
  to_archive(id, project_type) {
    return HTTP.get(`projects/${id}/to_archive/?status=${project_type}`).then((response) => {
      return response.data;
    });
  },
  screen_foresact(id) {
    return HTTP.get(`projects/${id}/screen_foresact/`).then((response) => {
      return response.data;
    });
  },

  get_snapshot(id, date){
    return HTTP.get(`projects/${id}/get_snapshot/?${date?`date=${date}`:''}`).then((response) => {
      return response.data;
    });
  },

  update_snapshot(id, data) {
    return HTTP.post(`projects/${id}/update_snapshot/`, data).then(response => {
      return response.data
    })
  },

  change_snapshot_verified(data) {
    return HTTP.post(`projects/change_snapshot_verified/`, data).then((response) => {
      return response;
    });
  },

  run_project_snapshoting_task() {
    return HTTP.get(`projects/run_project_snapshoting_task/`).then((response) => {
      return response;
    });
  },


  get(id, date, country_code, info_by_date=false) {
    return HTTP.get(`projects/${id}/?info_by_date=${info_by_date}&${date?`date=${date}`:''}&${country_code?`country_code=${country_code}`:''}`).then((response) => {
      return response.data;
    });
  },
  get_simple(id) {
    return HTTP.get(`projects/${id}/get_simple/`).then((response) => {
      return response.data;
    });
  },

  get_simple_list(active) {
    return HTTP.get(
      `projects/get_simple_list/${active ? `?active=${active}` : ""}`
    ).then((response) => {
      return response.data;
    });
  },
  get_select_list() {
    return HTTP.get(
      `projects/get_select_list/`
    ).then((response) => {
      return response.data;
    });
  },
  choices(name) {
    return HTTP.get(
      `choices?name=${name}`
    ).then((response) => {
      return response.data;
    });
  },

  get_images_info(id, date, country_code) {
    return HTTP.get(`projects/${id}/get_images_info/?date=${date||""}&country_code=${country_code}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  get_stat_images_info(id, date) {
    return HTTP.get(`projects/${id}/get_stat_images_info/?date=${date}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  get_full(id, date, country_code, info_by_date=false) {
    return HTTP.get(`projects/${id}/get_full/?date=${date}&country_code=${country_code}&info_by_date=${info_by_date}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  get_for_edit(id) {
    return HTTP.get(`projects/edit/?project_id=${id?id:""}`).then((response) => {
      return response.data;
    });
  },

  get_tr_settings(id){
    return HTTP.get(`projects/get_tr_settings/?project_id=${id?id:""}`).then((response) => {
      return response.data;
    });
  },

  keywords(id, state, date, country_code, info_by_date=false, per_page=10, page=1) {
    return HTTP.get(
      `projects/${id}/keywords/?state=${state}&date=${date}&country_code=${country_code}&info_by_date=${info_by_date}&size=${per_page}&page=${page}`
    ).then((response) => {
      return response.data;
    });
  },

  light_keywords(id) {
    return HTTP.get(
      `projects/${id}/light_keywords/`
    ).then((response) => {
      return response.data;
    });
  },

  get_themes(id) {
    return HTTP.get(
      `projects/${id}/themes/`
    ).then((response) => {
      return response.data;
    });
  },
  get_stat_by_keywords(id, query) {
    return HTTP.get(
      `projects/${id}/stat_by_keywords/?${query}`
    ).then((response) => {
      return response.data;
    });
  },
  active_images_dates(id) {
    return HTTP.get(
      `projects/${id}/active_images_dates/`
    ).then((response) => {
      return response.data;
    });
  },
  image_keywords(id, state, date, country_code) {
    return HTTP.get(
      `projects/${id}/image_keywords/?state=${state}&date=${date}&country_code=${country_code}`
    ).then((response) => {
      return response.data;
    });
  },
  delete_keyword(id, key_id) {
    return HTTP.delete(`projects/${id}/delete_keyword/?key=${key_id}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  links(id, tone, page, order) {
    order = order ? order : "id";
    return HTTP.get(
      `projects/${id}/links/?tone=${tone}&page=${page}&order=${order}`
    ).then((response) => {
      return response.data;
    });
  },
  uniq_images(id, query) {
    return HTTP.post(
      `projects/${id}/uniq_images/`, query
    ).then((response) => {
      return response.data;
    });
  },
  merge_image(id, query) {
    return HTTP.post(
      `projects/${id}/merge_image/`, query
    ).then((response) => {
      return response.data;
    });
  },
  get_chart_data(id, data) {
    return HTTP.post(`projects/${id}/chart_data/`, data).then((response) => {
      return response.data;
    });
  },

  get_backlinks_first_info(id,  data) {
    return HTTP.post(`projects/${id}/get_backlinks_first_info/`,  data).then((response) => {
      return response.data;
    });
  },

  update_related_request_tone(id,  data) {
    return HTTP.post(`projects/${id}/update_related_request_tone/`, data).then((response) => {
      return response.data;
    });
  },

  get_related_request(id,  data){
    return HTTP.post(`projects/${id}/get_related_request/`,  data).then((response) => {
      return response.data;
    });
  },

  update_backlink(id, data) {
    return HTTP.post(`projects/${id}/update_backlink/`,  data).then((response) => {
      return response.data;
    });
  },


  get_backlink_stat(data) {
    return HTTP.post(`projects/get_backlink_stat/`,  data).then((response) => {
      return response.data;
    });
  },

  

  start_scrapping_backlink(id) {
    return HTTP.post(`projects/${id}/start_scrapping_backlink/`).then((response) => {
      return response.data;
    });
  },

  get_backlink_excel_report(id, data) {
    return HTTP.post(`projects/${id}/get_backlink_excel_report/`,  data, {responseType: 'blob'}).then((response) => {
      return response;
    });
  },

  delete_backlink(id,backlink_id) {
    return HTTP.delete(`projects/${id}/delete_backlink/?backlink_id=${backlink_id}`);
  },

  full_stat(id, data) {
    return HTTP.post(`projects/${id}/full_stat/`, data).then((response) => {
      return response.data;
    });
  },
  change_sort(data) {
    return HTTP.post(`projects/set_sort/`, data).then((response) => {
      return response.data;
    });
  },
  get_full_stat(filter) {
    return HTTP.get(`projects/get_full_stat/?${filter}`).then((response) => {
      return response.data;
    });
  },
  get_forecast_stat(id, filter) {
    return HTTP.get(`projects/${id}/get_forecast_stat/?${filter}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  set_active(id, active) {
    return HTTP.post(`projects/${id}/set_active/?active=${active}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  links_info(id, query) {
    return HTTP.post(`projects/${id}/links_info/`, query).then((response) => {
      return response.data;
    });
  },
  start_search(id, data) {
    return HTTP.post(`services/${id}/start_today_search/`, data).then((response) => {
      return response.data;
    });
  },

  bind_link(project_id, date) {
    return HTTP.get(`services/${project_id}/bind_link/${date?`?date=${date}`:''}`).then((response) => {
      return response.data;
    });
  },

  content_search(project_id) {
    return HTTP.get(`services/${project_id}/content_search/`).then((response) => {
      return response.data;
    });
  },

  change_audit_date(project_id, data) {
    return HTTP.post(`services/${project_id}/change_audit_date/`, data).then((response) => {
      return response.data;
    });
  },

  project_state(project_id) {
    return HTTP.get(`services/project_state/?project_id=${project_id}`).then((response) => {
      return response.data;
    });
  },


  parser_state(country_code, date) {
    return HTTP.get(`services/parser_state/?country_code=${country_code}${date?`&date=${date}`:''}`).then((response) => {
      return response.data;
    });
  },

  parser_state_tr(country_code, date) {
    return HTTP.get(`services/parser_state_tr/?country_code=${country_code}${date?`&date=${date}`:''}`).then((response) => {
      return response.data;
    });
  },
  parser_start() {
    return HTTP.get(`projects/parser_start/`).then((response) => {
      return response.data;
    });
  },
  start_daily_search(project_id) {
    return HTTP.post(`services/${project_id}/start_daily_search/`).then((response) => {
      return response.data;
    });
  },
  add_od_access(project_id) {
    return HTTP.post(`services/${project_id}/add_od_access/`).then((response) => {
      return response.data;
    });
  },
  get_search_results(filter) {
    return HTTP.get(`services/get_search_results/?${filter}`).then((response) => {
      return response.data;
    });
  },
  theme_start() {
    return HTTP.get(`projects/theme_start/`).then((response) => {
      return response.data;
    });
  },
  scrapper_start() {
    return HTTP.get(`projects/scrapper_start/`).then((response) => {
      return response.data;
    });
  },
  start_search_image(id) {
    return HTTP.post(`services/${id}/start_search_image/`).then((response) => {
      return response.data;
    });
  },
  planData(data) {
    return HTTP.get(`projects/plan_data/?${data}`).then((response) => {
      return response.data;
    });
  },
  auditor_report(id) {
    return HTTP.get(`projects/${id}/auditor_report/`, {responseType: "blob"}).then(response => {
      return response.data
    })
  },
  get_history(id) {
    return HTTP.get(`projects/${id}/get_history/`).then(response => {
      return response.data
    })
  },
  mass_update(id, data) {
    return HTTP.post(`projects/${id}/mass_update/`, data).then(response => {
      return response.data
    })
  },

};

export const LinkApi = {
  update(object) {
    if (object.id)
      return HTTP.patch(`links/${object.id}/`, object).then((response) => {
        return response.data;
      });
    else
      return HTTP.post("links/", object).then((response) => {
        return response.data;
      });
  },
  update_image(object) {
    return HTTP.post("links/update_image/", object).then((response) => {
      return response.data;
    });
  },
  update_uniq_image(object) {
    return HTTP.post("links/update_uniq_image/", object).then((response) => {
      return response.data;
    });
  },
  update_tone(id, key, tone, link) {
    return HTTP.post(
      `links/${id}/update_tone/?tone=${tone}&key=${key}&image=${
        link ? link : ""
      }`
    ).then((response) => {
      return response.data;
    });
  },
  update_history_tone(link) {
    return HTTP.post(
      `links/update_history_tone/`, link
    ).then((response) => {
      return response.data;
    });
  },
  update_detele_type(id, type) {
    return HTTP.post(`links/${id}/update_detele_type/?type=${type}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  update_status(id, status) {
    return HTTP.post(`links/${id}/update_status/?status=${status}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  delete(object) {
    return HTTP.delete(`links/${object.id}/`);
  },
  list(query) {
    return HTTP.get(`links/?${query}`).then((response) => {
      return response.data;
    });
  },
  searches(query) {
    return HTTP.get(`searches/?${query}`).then((response) => {
      return response.data;
    });
  },
  get(id) {
    return HTTP.get(`links/${id}/`).then((response) => {
      return response.data;
    });
  },
  get_image(id) {
    return HTTP.get(`links/get_image/?link_id=${id}`).then((response) => {
      return response.data;
    });
  },
  get_chart_data(id, state, key, country_code) {
    return HTTP.get(
      `links/${id}/chart_data/?state=${state}&key=${key}&country_code=${country_code}`
    ).then((response) => {
      return response.data;
    });
  },
  get_content(id) {
    return HTTP.get(`links/${id}/contents/`).then((response) => {
      return response.data;
    });
  },
  get_history(id) {
    return HTTP.get(`links/${id}/history/`).then((response) => {
      return response.data;
    });
  },
  get_tracked_links(project) {
    return HTTP.get(`links/get_tracked_links/?project_id=${project||""}`).then((response) => {
      return response.data;
    });
  },
  get_tracked_info(id) {
    return HTTP.get(`links/${id}/get_tracked_info/`).then((response) => {
      return response.data;
    });
  },
  add_to_tracked(id) {
    return HTTP.post(`links/${id}/add_to_tracked/`).then((response) => {
      return response.data;
    });
  },
  remove_from_tracked(id) {
    return HTTP.post(`links/${id}/remove_from_tracked/`).then((response) => {
      return response.data;
    });
  },
  get_stat_data(id, filter) {
    return HTTP.get(`links/${id}/get_stat_data/?${filter ? filter : ""}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  get_report_data(id, filter) {
    return HTTP.get(
      `links/${id}/get_report_data/?${filter ? filter : ""}`
    ).then((response) => {
      return response.data;
    });
  },
  set_delete_price(id) {
    return HTTP.post(
      `links/set_delete_price/?project_id=${id}`
    ).then((response) => {
      return response.data;
    });
  },
  add_themes(id, data) {
    return HTTP.post(
      `links/${id}/add_theme/`, data
    ).then((response) => {
      return response.data;
    });
  },
  remove_theme(id, data) {
    return HTTP.post(
      `links/${id}/remove_theme/`, data
    ).then((response) => {
      return response.data;
    });
  },
  remove_theme_links(data) {
    return HTTP.post(
      `links/remove_theme_links/`, data
    ).then((response) => {
      return response.data;
    });
  },
  findSimilar(id, country_code) {
    return HTTP.get(
      `links/${id}/find_similar/?country_code=${country_code}`
    ).then((response) => {
      return response.data;
    });
  },
};
