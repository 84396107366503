<template>
  <div class="project row">
    <template v-if="layout==='3x2'">
      <div class="col-12 d-flex" style="justify-content: space-between">
        <h5 class="name">
          <router-link :to="{name: 'project_detail', params:{id: project.id}}">
            {{ project.name }} {{ project.id }}<span :class="`ms-3 badge ${project.project_type}`">{{ project.get_project_type_display }}</span>
          </router-link>

          <button v-if="project.id" @click="addToFavor();" class="btn"><i
              :class="`h3 text-warning bi bi-star${$store.getters.user.favorites.filter(x=>x.id===project.id).length?'-fill':''}`"></i>
          </button>

        </h5>
        <CountryChoice v-if="project && project.countries"
                       v-bind:active_country.sync="project.active_country_code"
                       v-bind:project_id="project.id"
                       v-bind:countries="project.countries"
                       v-bind:changeCountry="changeCountry"></CountryChoice>

      </div>

    </template>
    <template v-else>
       <div class="col-4">
        <h2 class="name">
          <router-link :to="{name: 'project_detail', params:{id: project.id}}">
            {{ project.name }}
          </router-link>
          <button v-if="project.id" @click="addToFavor();" class="btn"><i
              :class="`h3 text-warning bi bi-star${$store.getters.user.favorites.filter(x=>x.id===project.id).length?'-fill':''}`"></i>
          </button>
        </h2>


        <ul class="list-unstyled">
          <li>
            <span class="key">Статус:</span> <span
              :class="`badge ${project.project_type}`">{{ project.get_project_type_display }}</span>
          </li>
          <li>
            <span class="key">Менеджер проекта: </span><span>{{ project.pm_name }}</span>
          </li>
          <li v-if="project.project_type === 'active'">
            <span class="key">Ответственный SEO специалист: </span><span>{{ project.serm_name }}</span>
          </li>
          <li v-if="project.project_type === 'audit'">
            <span class="key">Аудитор: </span><span>{{ project.auditor_name }}</span>
          </li>
        </ul>


      </div>
      <div class="col-3">
        <CountryChoice v-if="project && project.countries"
                       v-bind:active_country.sync="project.active_country_code"
                       v-bind:project_id="project.id"
                       v-bind:countries="project.countries"
                       v-bind:changeCountry="changeCountry"></CountryChoice>

        <ul class="list-unstyled">
          <li v-for="country in project.countries">
            <span class="key">{{ country.name }}: </span>
            <span v-if="country.pf_code && country.pf_code.id">
                  <a v-if="country.pf_code.task_id" target="_blank"
                     :href="`https://serm-dr.planfix.ru/task/${country.pf_code.task_id}`">{{ country.pf_code.name }}</a>
                  <template v-else>{{ country.pf_code.name }}</template>
                </span>
            <span v-else class="text-danger">НЕ СВЯЗАН</span>
          </li>
        </ul>

      </div>
      <div class="col">
        <div class="row">
          <div class="col-7">
            <ul class="list-unstyled flex">
              <li>
                <span>Проект в работе с </span>
                <span class="badge grey">{{ $moment(project.date_create).format("DD MMMM YYYY") }}</span>
              </li>
              <li v-if="project.end_date">
                <span>Deadline </span>
                <span class="badge grey">{{ $moment(project.end_date, "YYYY-MM-DD").format("DD MMMM YYYY") }}</span>
              </li>
            </ul>
          </div>
          <div class="col-5">
            <router-link class="d-lg-flex flex-row justify-content-end text-dark" v-if="project.negative_info"
                         :to="{ name: 'project_prognoz', params: { id: project.id }}">
              <ul class="list-unstyled flex">
                <li>
                  <span>Внешний план </span>
                  <span class="badge grey">{{ project.negative_info.clf || 0 }}</span>
                </li>
                <li>
                  <span>Внутренний план </span>
                  <span class="badge grey">{{ project.negative_info.inf || 0 }}</span>
                </li>
                <li>
                  <span>Факт ТОП {{ project.active_state }} </span>
                  <span
                      :class="`badge ${((project.negative_info.negative_fact||0)  > (project.negative_info.clf||0 ))?'archive':((project.negative_info.negative_fact||0)  < (project.negative_info.clf||0 ))?'active':'grey'}`">{{
                      project.negative_info.negative_fact || 0
                    }}</span>
                </li>
              </ul>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col">

            <ul class="list-unstyled flex">
              <li v-if="project.percent_week !== undefined">
                <span>% нецелевой информации в сравнении с прошлой неделей: </span>
                <span :class="`badge ${project.percent_week > 0?'archive':'active'}`"><template
                    v-if="project.percent_week > 0">+</template>{{ project.percent_week }}%</span>
              </li>
              <li v-if="project.percent_month !== undefined">
                <span>% нецелевой информации в сравнении с прошлым месяцем: </span>
                <span :class="`badge ${project.percent_month > 0?'archive':'active'}`"><template
                    v-if="project.percent_month > 0">+</template>{{ project.percent_month }}%</span>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </template>

  </div>

</template>
<script>
import {ProjectApi} from "@/api/project";
import {UserApi} from "@/api/user";

export default {
  props: {
    project: {type: Object},
    layout: {type: String},
  },
  methods: {
    changeCountry: function (country) {
      if (this.project.active_country_code.code !== country.code) {
        this.project.active_country_code = country;
        this.getActiveProject(country.code);
      }
    },

    getActiveProject: function (country = null) {
      if (!this.project.stats || (this.project.stats && country)) {
        ProjectApi.get(this.project.id, '', country).then(response => {
          for (let key in response) {
            this.project[key] = response[key]
          }
        })
      }
    },
    addToFavor() {
      let $this = this;
      UserApi.add_favorite(this.project.id).then(() => {
        $this.project.favorite = !$this.project.favorite
        $this.$notify({
          type: 'success ',
          text: `${this.project.favorite ? 'Добавлено в избранное' : 'Удалено из избранного'}`
        });
        $this.$store.dispatch('get_user');
      })
    },

  }

}
</script>
