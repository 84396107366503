<template>
  <div id="mobile_app">
<!--    <PWAPromt></PWAPromt>-->
    <notifications/>
    <nav>
      <router-link class="logo" to="/"></router-link>

      <div v-if="$route.meta.show_title" class="title">
        <div class="me-2">{{ $route.meta.title }}</div>
        <div>
          <button @click="query_open=!query_open" class="btn btn-sm btn-outline-light"><i class="bi bi-search"></i>
          </button>
        </div>

      </div>
      <div class="burger">
        <div v-if="$route.meta.show_burger">
          <ul class="navbar-nav">
            <li class="nav-item dropdown" style="position: inherit">
              <button class="btn btn-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-list"></i>
              </button>
              <ul class="dropdown-menu dropdown-menu-dark">
                <li v-if="$route.name!=='project_detail'">
                  <router-link class="dropdown-item"
                               :to="{name: 'project_detail', params: {id: $route.params.id}}">Проект
                  </router-link>
                </li>
                <li v-if="$route.name!=='project_result'">
                  <router-link :to="{name: 'project_result', params: {id: $route.params.id}}" class="dropdown-item"
                  >Топ ссылок
                  </router-link>
                </li>
                <li v-if="$route.name!=='project_forecast'">
                  <router-link :to="{name: 'project_forecast', params: {id: $route.params.id}}" class="dropdown-item"
                  >План / факт
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <ul :class="`dropdown-menu dropdown-menu-dark ${query_open?'d-block':''}`">
        <li>
          <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3" role="search">
            <input type="search" :value="query" @input="e=>query=e.target.value"
                   class="form-control form-control-dark text-bg-dark"
                   placeholder="Поиск..."
                   aria-label="Поиск">
          </form>
          <ul v-if="query" class="search-result">
            <li><h6 class="dropdown-header">Результаты поиска</h6></li>
            <template v-if="searches.length && query.length>3">
              <li v-for="search in searches">
                <a class="dropdown-item search-link" @click.prevent="getProject(search.id)">
                  {{ search.name }} <span
                    :class="`badge ${search.project_type}`">{{ search.get_project_type_display }}</span>
                </a>

              </li>
            </template>
            <li v-else><p class="dropdown-item">Ничего не найдено</p></li>
          </ul>
        </li>


      </ul>
    </nav>

    <!--    {{$route.name}}-->
    <div :class="`container-wrap ${$route.name} ${$route.name!=='login'?'inner':''}`">
      <router-view/>
      <span v-if="$store.getters.load" class="loader"></span>
    </div>

  </div>
</template>

<script>
import {HTTP} from '@/api/common'
import axios from 'axios'
import {MobileApi} from "@/api/mobile";
// import PWAPromt from "@/components/PWAPromt.vue";


export default {
  name: 'MobileApp',
  // components: {PWAPromt},
  data() {
    return {
      query_open: false,
      query: "",
      request: false,
      searches: []
    }
  },
  async mounted() {
    await import('bootstrap-icons/font/bootstrap-icons.css');
    await import('@/assets/scss/mobile/mobile.scss');
    await import("bootstrap/js/index.esm");

  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'RA';
      }
    },
    query: function (val) {
      if (val.length > 3) {
        this.searchProject()
      } else {
        this.searches = []
      }

    },
  },
  methods: {
    getProject(id) {
      let $this = this;
      $this.$router.push(`/projects/${id}/`);
      MobileApi.list(id).then(response => {
            response.results.forEach(project => {
              $this.$store.dispatch("updateFavorite", project)
              $this.query = "";
              $this.query_open = false;
              $this.$router.push(`/projects/${id}/`);
            })
          }
      )
    },
    cancelSearch() {
      this.request.cancel();
      this.request = null;
    },
    searchProject: function () {
      if (this.query.length >=3) {
        let $this = this;
        if (this.request) this.cancelSearch();
        const axiosSource = axios.CancelToken.source();
        this.request = {cancel: axiosSource.cancel};
        HTTP.get(`projects/search_by_q/?name=${this.query}`, {cancelToken: axiosSource.token}).then(resp => {
          $this.request = null;
          $this.searches = resp.data
        })
      }
      else this.searches = [];
    },
  }
  ,
  created: function () {
    let $this = this;
    this.$store.dispatch('refresh_token');

    setInterval(function () {
      $this.$store.dispatch('refresh_token');
    }, 1000 * 60 * 5);
  }
}
</script>