<template>
  <tbody>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ organization.name }} <br>
      <button class="btn btn-sm btn-outline-dark" v-if="organization.content_tasks.length"
              @click="open_task=!open_task">Задачи на тексты
      </button>
    </td>
    <td :class="($moment().diff($moment(organization.date_end), 'days')>0)?'bg-danger':($moment().diff($moment(organization.date_end), 'days'))>-7?'bg-warning':''">
      {{ organization.date_end }}
    </td>
    <td>
      {{ organization.users_count }}
    </td>
    <td>{{ organization.site }}</td>
    <td class="d-flex">
      <MultiSelect
          v-model="organization.managers"
          :options="managers"
          :required="false"
          name="Менеджеры"
          :only_value="true"
          :multi="true"
      >
      </MultiSelect>
      <a v-if="organization.users_count" class="btn btn-sm btn-outline-dark" :href="loginUrl()" target="_blank"
         title="Вход от имени организации">Вход <i class="bi bi-box-arrow-in-right"></i></a>
      <router-link :to="{name: 'clients_edit', params: {client_id: organization.id}}"
                   class="btn btn-sm btn-outline-dark" title="Редактировать"><i class="bi bi-pencil-fill"></i>
      </router-link>
    </td>
  </tr>
  <template v-if="open_task">
    <tr>
      <th>-</th>
      <th colspan="2">Название</th>
      <th>Статус</th>
      <th>Комментариев</th>
      <th>Сообщений</th>
    </tr>
    <tr v-for="text in organization.content_tasks">
      <td></td>
      <td colspan="2">
        <router-link :to="{name: 'client_text', params: {client_id: organization.id, text_id: text.id}}">{{ text.text }}</router-link>
      </td>
      <td>{{ text.get_status_display }}</td>
      <td>{{ text.comments }}</td>
      <td>{{ text.chat }}</td>
    </tr>
  </template>
  </tbody>
</template>

<script>
import {OrganizationApi} from "@/api/organization";

export default {
  name: "ClientSmall",
  props: {
    organization: Object,
    managers: Object,
    index: Number
  },
  data() {
    return {
      open_task: false
    }
  },
  watch: {
    "organization.managers": {
      deep: true,
      handler(val) {
        OrganizationApi.update({"id": this.organization.id, "managers": val})
      }
    }
  },
  mounted: function () {

  },
  methods: {
    loginUrl() {
      const hash = btoa(JSON.stringify({jwt: this.$store.getters.jwt, org: this.organization.id}))
      return `${location.protocol}//${this.organization.site}?hash=${hash}`
    }
  },
}
</script>
<style scoped lang="scss">
td.d-flex {
  gap: 10px;
  flex-direction: row;

  .select {
    width: 250px;
  }

  button, a {
    max-height: 35px;
  }
}
</style>
