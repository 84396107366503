<template>
  <main class="dashboard links">
    <div class="mb-4 row">
      <h2 class="col">Организации</h2>

    </div>
    <div class="filters" v-if="$store.getters.user.permission.actions.indexOf('client_add')+1">
      <router-link :to="{name: 'clients_add'}" class="btn btn-sm btn-outline-dark">+ Добавить</router-link>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="table-responsive d-flex" style="height: auto; overflow: visible">
          <table class="table">
            <thead>
            <tr>
              <th scope="col"> №</th>
              <th scope="col">Название</th>
              <th scope="col">Срок действия</th>
              <th scope="col">Количество <br> пользователей</th>
              <th scope="col">Домен</th>
              <th scope="col">Доступы</th>
            </tr>
            </thead>
<!--            <tbody>-->
            <template v-for="(organization, index) in organizations">
              <ClientSmall
                  :organization="organization"
                  :index="index"
                  :managers="managers"></ClientSmall>
            </template>
<!--            </tbody>-->
          </table>
        </div>
      </div>
    </div>


  </main>
</template>

<script>
import {UserApi} from "@/api/user";
import {OrganizationApi} from "@/api/organization";
import ClientSmall from "@/views/clients/ClientSmall.vue";

export default {
  name: "ClientView",

  data() {
    return {
      organizations: [],
      accounts: [],
      managers: [],
    }
  },
  components: {ClientSmall},
  mounted: function () {
    UserApi.managers().then(response => {
      this.managers = response.map(x => {
        return {id: x.id, name: x.fullname, post_type: x.post_type, is_active: x.is_active}
      });
    });
    OrganizationApi.list().then(response => this.organizations = response)
  },
  methods: {},
}
</script>
<style scoped lang="scss">

</style>
