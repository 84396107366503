<template>
  <div class="div p-2" v-if="link">
    <div class="d-flex" style="gap: 5px;">
      <button v-if="!image" class="btn btn-sm btn-outline-primary" @click="closeLink(); link=null; graph_keyword='';"><i
          class="bi bi-arrow-left-short me-1"></i>
        Назад
      </button>
      <router-link class="btn btn-sm btn-primary"
                   :to="`/links_statistics/${link.id}/`">Динамика ссылки
      </router-link>
      <router-link v-if="link.has_backlink" class="btn btn-sm btn-primary mt-1"
                   :to="{name: 'project_backlinks',
                                          params: {id: project_id, type: 'back'}, query: {link: link.link}}">Backlinks
      </router-link>
      <b v-if="link.cross" class="ms-5">Пересечение с выдачей картинок</b>
    </div>
    <div class="d-flex mt-3 mb-3">
      <div class="link col">
        <h2>
          <router-link :to="`/projects/${$route.params.id}/links/${link.id}/history/`"
                       title="История изменений">
            <i class="bi bi-clock-history"></i></router-link>
          <a :href="link.link" class="text-dark" target="_blank">{{ link.link }}

          </a>
        </h2>
      </div>
    </div>

    <div class="link-detail">
      <div class="item">
        <div class="head">Тональность</div>
        <ul class="dropdown list">
          <template
              v-if="project.project_type !== 'archive' && ($store.getters.user.permission.link_fields.indexOf('tone') + 1)">

            <template v-for="(tone, key) in tones">
              <li v-if="!tone.no_filter"
                  v-on:click="link.get_tone_display=tone.name;
                           link.kind_type='manual';
                          link.get_kind_type_display='Ручной';
                          link.type_tone='new';
                          link.get_type_tone_display='Новый';
                          link.tone=key;
                         updateLink(link)"
                  :class="`dropdown-item ${(tone.synonym.indexOf(link.tone)+1)?`active bg-${key}`:''}`">
                {{ tone.name }}
              </li>
            </template>
          </template>
          <template v-else>
            <li :class="`dropdown-item active bg-${link.tone}`">
              {{ link.get_tone_display }}
            </li>
          </template>
        </ul>
      </div>
      <template v-if="link.tone">
        <div class="item">
          <div class="head">Тип тона</div>
          <ul class="dropdown list">
            <template
                v-if="project.project_type !== 'archive' && ($store.getters.user.permission.link_fields.indexOf('type_tone') + 1)">
              <li v-for="(tone, key) in  type_tones"
                  v-on:click="link.type_tone = key; link.get_type_tone_display=tone.name; updateLink(link)"
                  :class="`dropdown-item pb-0 pt-0 ${link.type_tone === key?'active':''}`">{{ tone }}
              </li>
            </template>
            <template v-else>
              <li class="dropdown-item active">
                {{ link.get_type_tone_display }}
              </li>
            </template>
          </ul>
        </div>
      </template>
      <div class="item">
        <div class="head">Метод воздействия</div>
        <ul class="dropdown list">
          <template
              v-if="project.project_type !== 'archive' || ($store.getters.user.permission.link_fields.indexOf('delete_type') + 1)">
            <li v-for="del_type in $DELETE_TYPES"
                v-on:click="link.delete_type = del_type.id; updateLink()"
                :class="`dropdown-item pb-0 pt-0 ${link.delete_type === del_type.id?'active':''}`">{{ del_type.name }}
            </li>
          </template>
          <template v-else>
            <li class="dropdown-item active">
              {{ link.get_delete_type_display }}
            </li>
          </template>
        </ul>

      </div>
      <div class="item">
        <div class="head">Суд</div>
        <ul class="dropdown list">
          <template
              v-if="project.project_type !== 'archive' || ($store.getters.user.permission.link_fields.indexOf('court') + 1)">
            <li v-for="court in $COURT_TYPE"
                v-on:click="link.court = court.id; updateLink()"
                :class="`dropdown-item pb-0 pt-0 ${link.court === court.id?'active':''}`">{{ court.name }}
            </li>
          </template>
          <template v-else>
            <li class="dropdown-item active">
              {{ link.get_court_display }}
            </li>
          </template>
        </ul>

      </div>
      <div class="item">
        <div class="head">Топ</div>
        <div class="dropdown list">
          <p>10</p>
          <p>20</p>
          <p>100</p>
        </div>
      </div>
      <div class="item">
        <div class="head">Дата</div>
        <div class="dropdown list">
          <p>{{ link.top_10_date }}</p>
          <p>{{ link.top_20_date }}</p>
          <p>{{ link.top_100_date }}</p>
        </div>
      </div>
      <div class="item">
        <div class="head">Запрос</div>
        <div class="dropdown list">
          <p v-for="key in places">{{ key.key }}</p>
        </div>
      </div>
      <div class="group">
        <div class="sub-name">
          Позиция
        </div>
        <div class="items">
          <div class="item">
            <div class="head"><i class="ico yandex"></i></div>
            <div class="dropdown list">
              <p v-for="key in places">{{ key.yandex }}</p>
            </div>
          </div>
          <div class="item">
            <div class="head"><i class="ico google"></i></div>
            <div class="dropdown list">
              <p v-for="key in places">{{ key.google }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="group">
        <div class="sub-name">
          Лучшая позиция
        </div>
        <div class="items">
          <div class="item">
            <div class="head"><i class="ico yandex"></i></div>
            <div class="dropdown list">
              <p>{{ link.best_y }}</p>
            </div>
          </div>
          <div class="item">
            <div class="head"><i class="ico google"></i></div>
            <div class="dropdown list">
              <p>{{ link.best_g }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="head">Примечание</div>
        <div class="dropdown list">
          <textarea v-model="link.comment"
                    :disabled="!($store.getters.user.permission.link_fields.indexOf('comment') + 1)" cols="30" rows="10"
                    class="w-100"></textarea>
          <button class="btn btn-sm btn-outline-dark" @click="updateLink(link)">Сохранить</button>
        </div>
      </div>

    </div>
    <div class="col-md-5">
      <label for="country" class="form-label">Ключевые слова</label>
      <select class="form-select" v-model="graph_keyword" id="country" required="">
        <option value="">Лучшая позиция</option>
        <option v-for="key in keywords" :value="key.id">{{ key.name }}</option>
      </select>
    </div>
 <div id="legend-container" class="legend"></div>
    <div class="chart">

      <div :style="`height: 420px; width: ${chart_data?.labels?.length*25}px;`">
        <Line
            ref="linkChart"
            id="my-chart-id"
            v-if="chart_data"
            :options="chartOptions"
            :data="chart_data"
            :plugins="[htmlLegendPlugin]"
        />
      </div>
    </div>


  </div>
</template>

<script>
import {LinkApi, ProjectApi} from '@/api/project'
import RangeSlider from "@/plugins/RangeSlider"

import {Line} from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, Filler, PointElement)
import htmlLegendPlugin from "@/components/legendPlugin";
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ChartDataLabels);

ChartJS.defaults.plugins.legend.display = false;
ChartJS.defaults.set('plugins.datalabels', {
  color: '#000000',
  font:{
    weight: "bold",
  },
  offset: 0,
  align: "top"
});

export default {
  name: "LinkDetail",
  props: ["link_id", 'project_id', 'date', 'keywords', 'audit', 'country_code', 'image'],
  components: {
    Line, RangeSlider,
  }, data() {
    return {
      htmlLegendPlugin: htmlLegendPlugin,
      graph_keyword: '',
      edit_comment: false,
      link: {},
      project: {},
      datacollection: null
    }
  },

  mounted: function () {
    if (this.link_id) this.getData();
    ProjectApi.get_simple(this.project_id).then(response => this.project = response);
  },
  computed: {

    places() {
      let places;
      if (this.link?.id) {
        places = Object.keys(this.link.y_search)
        places = places.concat(Object.keys(this.link.g_search))
        places = [...new Set(places)]
        places = places.map((x) => {
          return {key: x, yandex: this.link.y_search[x] || "-", google: this.link.g_search[x] || "-"}
        })
      }
      return places
    },
        chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        layout: {
          padding: 40
        },
        scales: {
          x:
              {
                grid: {
                  display: false
                },
              },
          y:
              {
                grid: {
                  display: false
                },

                ticks: {
                  display: false,
                },
                reverse: true,
                maxTicksLimit: Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100)) + 10,
                suggestedMax: Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100)) + 10,
                max: Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100)) + 10,

              }
        },
      }
    },
    chart_data() {
      let $this = this;
      if (this.datacollection && this.datacollection.labels) {
        let new_data = [];
        for (let i in this.datacollection.datasets) {
          let d = this.datacollection.datasets[i];
          let n_d = {
            borderColor: d.borderColor,
            data: d.data.slice($this.value[0], $this.value[1]),
            fill: d.fill,
            label: d.label,
            lineTension: d.lineTension,
            type: d.type,
          };
          new_data.push(n_d);
        }
        return {
          labels: this.datacollection.labels.slice(
              $this.value[0],
              $this.value[1]
          ),
          datasets: new_data,
        };
      } else return this.datacollection;
    },

    tones: function () {
      return this.$TONES;
    },
    type_tones: function () {
      return this.$getTypes(this.link.tone);
    },
  },

  watch: {
    graph_keyword: function (val) {
      if (this.link) {
        this.fillData();
      }
    },
    date: function (val) {
      this.getData();
    },
    country_code: function (val) {
      this.getData();
    }
  },
  methods: {
    getData: function () {
      let $this = this;
      LinkApi.get(this.link_id, this.date).then(response => {
        $this.link = response;
        if (!$this.link.comment) $this.edit_comment = true;
        if (!$this.datacollection) this.fillData()
      })
    },
    updateLink: function () {
      let $this = this;
      LinkApi.update($this.link).then(response => {
        $this.link = {
          ...$this.link,
          ...response
        };
        this.$notify({
          type: 'success ',
          text: 'Данные обновлены'
        });
        if ($this.link.comment) $this.edit_comment = false;

        this.$parent.getKeywords($this.project_id)
      })
    },
    fillData() {
      let $this = this;
      LinkApi.get_chart_data(this.link.id, 100, this.graph_keyword, this.country_code).then(response => {
        let labels = response.labels;
        if (labels.length && labels.length < 30) {
          Date.prototype.addDays = function (days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
          };
          let now = new Date(), last = labels[labels.length - 1];

          while (labels.length < 10) {

            let last_date = new Date(now.getFullYear(), parseInt(last.split('.')[1]), parseInt(last.split('.')[0]));
            let new_date = last_date.addDays(1);
            last = `${String(new_date.getDate()).padStart(2, '0')}.${String(new_date.getMonth()).padStart(2, '0')}`;
            labels.push(last)
          }
        }
        $this.value = [0, labels.length];
        $this.value_max = labels.length;
        $this.datacollection = {
          labels: labels,
          datasets: response.data
        };

      })
    },
    closeLink() {
      this.$parent.closeLink();
    }

  }
}
</script>

<style scoped lang="scss">

.chart {
  overflow-x: scroll;
}

.link-detail {
  display: flex;
  gap: 20px;
  margin-top: 30px;

  .group {
    margin-top: -30px;
    margin-right: -20px;
  }

  .sub-name {
    border-radius: 13px 13px 0 0;
    border: 1px solid rgba(244, 244, 244, 1);
    height: 30px;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    padding: 5px 12px;
  }

  .items {
    display: flex;
    flex-direction: row;
    width: 100%;

    .item {
      width: 100%;
      text-align: center;

      .head {
        margin-right: auto;
        width: 100%;
      }
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .head {
      margin-right: -20px;
      background: rgba(244, 244, 244, 1);
      font-weight: 600;
      font-size: 14px;
      padding: 5px 0;
      height: 30px;
    }
  }
}

</style>
