import Router, {createRouter, createWebHistory} from 'vue-router'
import PageNotFound from '@/views/404.vue'
import store from '@/store/index'
import MobileLogin from "@/mobile/Login.vue";
import Front from "@/mobile/Front.vue";
import ProjectDetail from "@/mobile/ProjectDetail.vue";
import LinkList from "@/mobile/LinkList.vue";
import SearchResult from "@/mobile/SearchResult.vue";
import Forecast from "@/mobile/Forecast.vue";
import authyRouters from "@/authy/router";


let mobile_router = new createRouter({
    history: createWebHistory(),
    base: '/',
    routes: [
        ...authyRouters,

        {
            path: '/',
            name: 'base',
            component: Front,
            meta: {
                title: 'Избранное',
                show_title: true,
                show_burger: false,
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: MobileLogin,
            meta: {
                title: 'Вход',
                show_title: false,
                show_burger: false,
                notAuth: true
            }
        },
        {
            path: '/projects/:id/',
            name: 'project_detail',
            component: ProjectDetail,
            meta: {
                title: 'Проект',
                show_title: true,
                show_burger: true,
                requiresAuth: true
            }
        },
        {
            path: '/projects/:id/search',
            name: 'project_result',
            component: SearchResult,
            meta: {
                requiresAuth: true,
                title: 'Топ ссылок',
                show_title: true,
                show_burger: true,
            }
        },
        {
            path: '/projects/:id/info',
            name: 'links_info',
            component: LinkList,
            meta: {
                title: '',
                requiresAuth: true,
                show_title: true,
                show_burger: true,
            }
        },
        {
            path: "/projects/:id/forecast",
            name: "project_forecast",
            component: Forecast,
            meta: {
                requiresAuth: true,
                title: 'План / Факт',
                show_title: true,
                show_burger: true,
            },
        },
        {
            path: "/404", name: '404',
            component: PageNotFound,
            meta: {
                title: 'Страница не найдена'
            }
        },
        {
            path: "/:catchAll(.*)", name: '404',
            redirect: '/404',
        }
    ]
});

export default mobile_router
mobile_router.beforeEach((to, from, next) => {
    store.commit('set_route', to);
    store.dispatch('init', (!to.meta.notAuth)).then(() => {
        store.dispatch('favorites');
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (store.getters.isLoggedIn) {
                next();
                return
            }
            next('login');
        } else if (to.matched.some(record => record.meta.notAuth)) {
            if (store.getters.isLoggedIn) {
                next('/')
            } else {
                next();
            }

        } else {
            next()
        }
    }, error => {
        console.log(error)
    })

});
