<script setup>
import {TextProjectApi} from '../api/project'
import {computed, ref, watch} from "vue";
import {UserApi} from "@/api/user";
import {notify} from "@kyvg/vue3-notification";
import {PersonApi} from "@/api/persons";

const managers = ref([]);
UserApi.managers({unique_text: true}).then(response => {
  managers.value = response.map(x => {
    return {id: x.id, name: x.fullname}
  });
});
const projects = ref([]);
const editProject = ref(Object);

TextProjectApi.list().then(response => projects.value = response);
const query = ref("");

const q_project = computed({
      get() {
        return projects.value.filter(project => project.name.toLowerCase().indexOf(query.value.toLowerCase()) + 1)
      }
    }
)
watch(editProject, (newConfig) => {
    if (editProject.value.person_id && typeof editProject.value.person_id == "number"){
      PersonApi.get(editProject.value.person_id).then((r)=>editProject.value.person_id = r)
    }
}, { deep: true });

function createProject() {
  let data = JSON.parse(JSON.stringify(editProject.value))
  if (data.person_id) data.person_id = data.person_id.id
  TextProjectApi.update(data).then(response => {
    if (editProject.value.id) {
      let project = projects.value.filter(pr => pr.id === editProject.value.id)[0]
      projects.value.splice(projects.value.indexOf(project), 1, response);
    } else projects.value.push(response)
    notify({
      group: 'alert',
      type: 'success',
      text: 'Группа добавлена'
    })
    document.getElementById("project_edit").classList.remove("show");
  })
}
</script>


<template>
  <nav :class="`pb-4 text-white sidebar ${$store.getters.sidebar_state?'':'collapse'}`">
    <div class="hide-sidebar" @click="$store.dispatch('toggle_sidebar')">
      <i :class="`bi bi-chevron-double-${$store.getters.sidebar_state?'left':'right'}`"></i>

    </div>


    <div class="menu-group">
      <button class="btn btn-menu" @click="editProject={users:[]};$modal('show', 'project_edit');">Добавить проект <i
          class="bi bi-plus-circle ms-1"></i></button>
    </div>

    <div class="menu-group">
      <div class="search" role="search">
        <i class="bi bi-search"></i>
        <input type="search" class="form-control black" v-model="query" placeholder="Поиск..." aria-label="Поиск">
      </div>
    </div>
    <div class="menu-group">
      <p class="text-secondary">Проекты</p>
      <div v-for="project in q_project" class="d-flex">
        <router-link active-class="active" class="btn btn-menu"
                     :to="{name: 'text_project_detail', params:{id: project.id}}">
          {{ project.name }}
        </router-link>
        <button class="btn btn-outline-secondary" @click="editProject=project; $modal('show', 'project_edit');"><i
            class="bi bi-pencil-fill"></i></button>
      </div>

    </div>
    <modal modal_id="project_edit" class="text-black">
      <template v-slot:header>
        <h2>
          <template v-if="editProject.id">Редактирование проекта {{ editProject.name }}</template>
          <template v-else>Создание проекта</template>
        </h2>
      </template>

      <template v-slot:body>
        <form @submit.prevent="createProject" ref="createProject" class="needs-validation form-edit" novalidate=""
              v-if="editProject" style="height: 300px">
          <div class="row">

            <div
                :class="'col-8'+(editProject.person_id_error ?' form-error':'')">
              <label>Персона <sup>*</sup></label>
              <MultiSelect
                  v-model="editProject.person_id"
                  :options="[]"
                  :findFunction="PersonApi.list"
                  :multi="false"
                  name="Персоны"
                  :required="false"
                  :only_value="false"
              ></MultiSelect>
              <div class="error" v-if="editProject.person_id_error">{{ editProject.person_id_error }}</div>
            </div>
            <div :class="'col-8 mb-3'+ (editProject.name_error ?' form-error':'')">
              <label>Название проекта <sup>*</sup></label>
              <input type="text" placeholder="Название проекта" class="form-control" id="name"
                     v-model="editProject.name" required="">
              <div class="error" v-if="editProject.name_error">{{ editProject.name_error }}</div>
            </div>
            <div
                :class="'col-8'+(editProject.project_type_error ?' form-error':'')">
              <label>Пользователи <sup>*</sup></label>
              <MultiSelect
                  v-model="editProject.users"
                  :options="managers"
                  :multi="true"
                  :name="'Пользователи'"
                  :required="true"
                  :only_value="true"
              ></MultiSelect>
              <div class="error" v-if="editProject.project_type_error">{{ editProject.project_type_error }}</div>
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <button class="btn btn-sm btn-secondary" @click="$modal('hide', 'project_edit');">Отмена</button>
        <button class="btn btn-sm btn-success" @click.prevent="createProject">
          <template v-if="editProject.id">Сохранить</template>
          <template v-else>Добавить</template>
        </button>
      </template>
    </modal>

  </nav>
</template>
<style>
.child-menu {
  border-left: 1px solid #363535;
  padding-left: 5px !important;
  margin-left: 5px;
  padding-top: 10px;
}

.modal .dropdown-option {
  height: 130px !important;
}
</style>