<template>
  <div class="countries" aria-label="Страны проекта">
    <button type="button" v-for="country in countries" @click="setActiveCountry(country.code);changeCountry(country, project_id);"
            :class="'country' + (active_country?.code===country.code?' active':'')">
      {{ country.name }}
    </button>
  </div>
</template>

<script>
import {ProjectApi} from '@/api/project'
export default {
  name: "CountryChoice",
  props: ['project_id', 'active_country', 'countries', 'changeCountry'],
  data() {
    return {}
  },
  methods: {
    setActiveCountry(country) {
      if (this.project_id) {
         let data = {projects: {}}
        data.projects[this.project_id] = {country_code: country}
        this.$store.dispatch("setSettings", data)
      }
    }
  }

}
</script>

<style scoped>

</style>
