import Settings from '@/views/user/Settings.vue'
import Login from '@/views/user/Login.vue'
import ForcedPassword from '@/views/user/ForcedPassword.vue'
import RestorePassword from '@/views/user/RestorePassword.vue'
import ServerInfo from '@/views/services/ServerInfo.vue'
import ServicePage from '@/views/services/ServicePage.vue'
import ServicePageTR from "@/views/services/ServicePageTR.vue";
import SearchQuakeView from '@/views/services/SearchQuakeView.vue'

import ClientView from "@/views/clients/ClientView.vue";
import ClientText from "@/views/clients/ClientText.vue";
import ClientAdd from "@/views/clients/ClientAdd.vue";

import LinkStat from '@/views/statistic/LinkStat.vue'
import FactForecast from "@/views/forecast/FactForecast.vue";
import ProjectHistory from '@/views/projects/edit/ProjectHistory.vue'


export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      notAuth: true
    }
  },
  {
    path: '/forced_password_change',
    name: 'forced_password_change',
    component: ForcedPassword,
    meta: {
      notAuth: true
    }
  },
     {
      path: '/cabinet/set_password/:uid/:token',
      name: 'set_password',
      component: RestorePassword,
      meta: {
        notAuth: true
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/info',
      name: 'server_info',
      component: ServerInfo,
      meta: {
        requiresAuth: true
      }
    },

  {
    path: '/services',
    name: 'services',
    component: ServicePage,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/clients',
    name: 'clients',
    component: ClientView,
    meta: {
      hasPerm: true,
      requiresAuth: true
    }
  },

  {
    path: '/clients/:client_id/text/:text_id',
    name: 'client_text',
    component: ClientText,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clients/add',
    name: 'clients_add',
    component: ClientAdd,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clients/:client_id',
    name: 'clients_edit',
    component: ClientAdd,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/services_tr',
    name: 'services_tr',
    component: ServicePageTR,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/links_statistics',
    name: 'links_statistics',
    component: LinkStat,
    meta: {
      requiresAuth: true,
      permissions: ['serm']
    },
  },
  {
    path: '/links_statistics/:link_id',
    name: 'links_statistics_detail',
    component: LinkStat,
    meta: {
      requiresAuth: true,
      permissions: ['serm']
    }
  },

  {
    path: "/projects/forecast/stat/",
    name: "forecast_stat",
    component: FactForecast,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/search_quake/",
    name: "search_quake",
    component: SearchQuakeView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/projects/:id/history/",
    name: "project_history",
    component: ProjectHistory,
    meta: {
      requiresAuth: true,
    },
  }
]
