import AuthyFront from '../AuthyFront.vue'


export default [
    {
        path: '/authy',
        name: 'authy',
        component: AuthyFront,
        meta: {
            requiresAuth: true,
            base_name: "authy",
            // hasPerm: true,
            title: 'Authy',
            // hasPerm: true
        },
        children: [

        ]
    }
]
