import {HTTP} from "./common";

export const PersonApi = {
    list(filter) {
        return HTTP.get(`persons/?name=${filter}`).then((response) => {
            return response.data;
        });
    },
    get(id) {
        return HTTP.get(`persons/${id}/`).then((response) => {
            return response.data;
        });
    },

};
